import React, {useState, useEffect, useRef} from "react";
import { graphql } from "gatsby";
import axios from "axios";
import moment from "moment";
import BackgroundImage from "gatsby-background-image";
import { Helmet } from "react-helmet";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {layoutOptions} from "../components/Content/RichText";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ForecastData from "../components/Forecast";

import style from "../components/Hero/Hero.module.css";

const IndexPage = ({data, location, ...props}) => {
    const content = data.contentfulHomePage;
    const richText = documentToReactComponents(content.content.json, layoutOptions);
    const title = `${content.pageTitle}`;
    const description = `${content.metaDescription}`;
    const metaImage = `https:${content.metaImage.file.url}`;

    return (
        <Layout location={location}>
          <SEO title={title} />
          <Helmet>
          <meta name="image" content={metaImage} />
          <meta property="og:image" content={metaImage} />
          <meta name="twitter:image" content={metaImage} />
          </Helmet>

          <ForecastData />
          {/*richText*/}

        </Layout>
    )
}

export default IndexPage;

export const indexQuery = graphql`
    query indexQuery {
        file(relativePath: {eq: "global-milk-cows.png"}) {
            childImageSharp {
                fluid(quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        contentfulHomePage {
            content {
                json
            }
            canonicalLink
            metaDescription
            metaImage {
                file {
                    url
                }
            }
            metaKeywords
            navigationLabel
            pageTitle
        }
    }`
