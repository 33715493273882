import React, {useState, useEffect} from "react";

import {
  TwitterShareButton
} from "react-share";

import {
  TwitterIcon
} from "react-share";


const SocialShare = ({data, url}) => {
    const [prices, setPrices] = useState('')
    useEffect (
        () => {
            let d = ``;
            for (let val of Object.values(data.slice(0, 4))) {
                d = d + val.month.slice(0,3) + ': ' + 'Butterfat $' + new Intl.NumberFormat().format(Math.trunc(val.forecast[0].price)) + ', SNF $' + new Intl.NumberFormat().format(Math.trunc(val.forecast[1].price)) + '\n'
            }
            setPrices(d)
        }, []
    )
    const hashTags = "#butterfat #snf #thegdipproject"
    const quote = `Global price (not a forecast)\n${prices}\n`

    if (!prices.length) return <div />

    return (
        <div>
            <TwitterShareButton title={quote} url={`https://www.globalmilk.net/`} hashtags={hashTags.replace(/#/g, '').split(' ')}>
                <TwitterIcon size={32} round={true} /> Tweet this
            </TwitterShareButton>
        </div>
    )
}
export default SocialShare
