import React, {useState, useEffect, useRef} from "react";
import {Link} from "gatsby"
import axios from "axios";
import moment from "moment";
import SocialShare from "../SocialShare";
import style from "./Forecast.module.css";

async function getBasePriceData(report_date) {
    let basePrice;
    const endpoint = `https://app.merchantdiary.com/api/v1/base-ingredient-price/`
    basePrice = axios.get(
        endpoint,
        {
            auth: {
                username: process.env.GATSBY_BASE_PRICE_USER,
                password: process.env.GATSBY_BASE_PRICE_PASS
            }
        }
    );
    const res = await basePrice;
    return res;
}

const getReportDate = () => {
    const today = moment().format('YYYY-MM-DD')
    const dateThreshold = moment().date() > 15
    const report = dateThreshold ?
        moment().add(0, 'M').startOf('month').format('YYYY-MM-DD') :
        moment().add(-1, 'M').startOf('month').format('YYYY-MM-DD')

    return today
}

const ForecastData = props => {
    const [forecast, setForecast] = useState({});
    const asOfDate = useRef(false)
    const nextRefreshDate = useRef()

    useEffect(
        () => {
            getBasePriceData(getReportDate())
            .then(result => {
                asOfDate.current = moment(result.data[0].as_of_date).format("MMMM DD, YYYY");
                nextRefreshDate.current = moment(result.data[0].next_refresh_date).format("MMM DD, YYYY")

                const groups = result.data.reduce((groups, val) => {
                    const date = val.contract_month;
                    const report_date = moment(date).isAfter(getReportDate());
                    if(report_date) {
                        if (!groups[date]) {
                            groups[date] = [];
                        }
                        groups[date].push(val);
                    }
                    return groups;
                }, {});

                const groupArrays = Object.keys(groups).map((date) => {
                    const month = moment(date).format('MMMM')
                    const data = {
                        date,
                        month,
                        forecast: groups[date]
                    }
                    return data
              }).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
              setForecast(groupArrays)
            });
        }, []
    )

    return (
        <>
        <div>
            <h2 className={`uk-text-center uk-margin-remove-vertical uk-margin-auto-left uk-margin-auto-right`}>
            Global Assessment
            </h2>
            {asOfDate.current ? <h3 className={`uk-text-center uk-margin-remove-vertical uk-margin-auto-left uk-margin-auto-right`}>As of {asOfDate.current}</h3> : null}
            <p className={`${style.heroLeader} uk-text-lead uk-text-center uk-width-1-2@s uk-margin-auto-left uk-margin-auto-right`}>The following prices are a forward assessment of dairy ingredient values in global markets in USD/Ton</p>
        </div>
        <div className={`uk-grid uk-grid-small uk-grid-match uk-child-width-1-2 uk-child-width-1-4@l uk-margin-bottom`} data-uk-grid>
            {forecast.length ?
                forecast.slice(0,4).map((data, i) =>
                    <div key={`${data.month}-${i}`}>
                        <div className={`${style.heroCard} uk-card uk-card-small uk-box-shadow-medium`}>
                            <div className="uk-card-header uk-padding-remove-bottom uk-grid uk-grid-collapse uk-width-1-1 uk-flex-bottom" data-uk-grid>
                                <div className="uk-width-auto">
                                    <h3>{data.month}</h3>
                                </div>
                            </div>
                            <div className="uk-card-body uk-padding-remove-top">
                                <div style={{borderTop: "2px solid #F1BF5E", marginTop: "4px"}}>
                                    {data.forecast.map((f, i) =>
                                        <div key={`${f.ingredient}-${i}`} className="uk-margin-remove uk-grid-collapse" data-uk-grid>
                                            <div className="uk-width-auto">
                                                <h4 className="uk-margin-remove">{f.ingredient === "BF" ? "Butterfat" : "SNF"}</h4>
                                            </div>
                                            <div className="uk-width-expand">
                                                <h4 className="uk-margin-remove-vertical uk-margin-small-left uk-text-right">${
                                                    new Intl.NumberFormat().format(Math.trunc(f.price))
                                                }</h4>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )
                : null
            }
        </div>
        <div className="uk-flex uk-flex-column uk-flex-middle">
            <p className={`${style.heroLeader} uk-text-lead uk-text-center uk-width-1-2@s uk-margin-remove-bottom`}>Our next price update will be on {nextRefreshDate.current}.</p>
            <small className="uk-text-center uk-width-1-2@s uk-margin-bottom uk-margin-remove-top">Refer to <Link to={`/our-method`}>Our Methods</Link> for information on the calculations. <sup>1</sup></small>
            {forecast.length ? <SocialShare data={forecast} /> : null}
        </div>
        </>
    )
}

export default ForecastData;
