import React, {useEffect} from "react";
import { useStaticQuery, graphql } from "gatsby";
import moment from "moment";
import BackgroundImage from "gatsby-background-image";
import ForecastData from "./Data";

import style from "./Forecast.module.css";

const IndexPage = ({...props}) => {
    const backgroundImage = useStaticQuery(graphql`
        query forecastImageQuery {
            file(relativePath: {eq: "global-milk-cows.png"}) {
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }`);

    return (
        <div className="uk-container uk-container-expand uk-light uk-padding-remove">
            <div className={`${style.topWrapHeight}`}>
                <div className={`${style.topContainer} uk-width-1-1`}>
                    <BackgroundImage
                          Tag="div"
                          fluid={backgroundImage.file.childImageSharp.fluid}
                          preserveStackingContext={true}
                          style={{
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'cover',
                              backgroundColor: "rgba(29, 38, 54, 0.65)",
                              backgroundPosition: `center center`,
                              display: `flex`,
                              alignItems: `center`,
                              height: `100%`
                            }}
                        >
                        <div className="uk-container uk-position-relative uk-light">
                            <div className={`uk-flex uk-flex-middle`}>
                                <div
                                    className={`uk-position-relative`}
                                    >
                                    <div
                                        className={`${style.heroCopy}`}>
                                        <ForecastData />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </BackgroundImage>
                </div>
            </div>
        </div>
    )
}

export default IndexPage;
